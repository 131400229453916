<template>
  <el-dialog
    v-model="visible"
    :title="'id' in specRowMsg ? '编辑空瓶商品' : '新增空瓶商品'"
    width="50%"
    :before-close="handleClose"
  >
    <el-form
      :model="specRowMsg"
      label-width="120px"
      ref="elForm"
      :rules="rules"
      v-loading="form.loading"
      :element-loading-text="form.loadText"
    >
      <el-form-item label="关联物料" prop="materielCode">
        <el-select-v2
          v-model="specRowMsg.materielCode"
          :options="materialOptions"
          placeholder="请输入物料编码或物料名称关键词"
          filterable
          clearable
          @change="handleChangeMateria"
          style="width: 70%"
        />
      </el-form-item>
      <el-form-item label="空瓶商品名称" prop="emptyBottleName">
        <el-input v-model="specRowMsg.emptyBottleName" style="width:70%" placeholder="请填写空瓶商品名称" />
      </el-form-item>
      <el-form-item label="空瓶图片">
        <el-upload
          :accept="uploadRule.extensions"
          list-type="picture"
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :http-request="changeImage"
        >
          <img v-if="specRowMsg.emptyBottleUrl" :src="specRowMsg.emptyBottleUrl" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="规格">
        <el-input v-model="specRowMsg.emptyBottleSpec" style="width:70%" placeholder="请填写规格" />
      </el-form-item>

      <el-form-item label="最小单位" prop="emptyBottleUnit">
        <el-select v-model="specRowMsg.emptyBottleUnit" placeholder="请选择最小单位" filterable>
          <el-option :label="item.name" :value="item.name" v-for="(item, index) in units" :key="index" />
        </el-select>
      </el-form-item>
    </el-form>

    <div style="text-align: center; margin-top: 10px;">
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
      <el-button type="primary" @click="handleSubmit" style="width: 100px;">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { headers, client } from '../../../../lin/util/uploadImg.js'
import { reactive, defineComponent, computed, ref, toRaw, onMounted, nextTick } from 'vue'
import API from '../../service/api.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { uploadFileMixin, uploadfileRuleMixin } from '@/mixins/uploadFile.js'

export default defineComponent({
  props: {
    dialogVisible: Boolean,
    specRowMsg: Object,
    units: Array,
    isEdit: Boolean,
  },

  setup(props, ctx) {
    const uploadRule = reactive({
      businessType: 'COMMODITY_EMPTY',
      extensions: '',
    })
    onMounted(() => {
      uploadfileRuleMixin({ businessType: uploadRule.businessType }).then(res => {
        uploadRule.extensions = res.extensions
      })
    })
    const form = reactive({
      loadText: '正在火速加载数据...',
      imgs: [],
      geoCode: [],
      loading: false,
    })

    onMounted(() => {
      queryEmptyBottleMaterielBindingInfo()
    })

    //关联物料
    const materialOptions = ref([])
    const queryEmptyBottleMaterielBindingInfo = async () => {
      let res = await API.queryEmptyBottleMaterielBindingInfo({
        skuCode: props.specRowMsg.code,
        materielCode: props.specRowMsg.materielCode,
      })
      materialOptions.value = res.data.map(item => {
        return {
          value: item.materielCode,
          label: item.materielCode + '_' + item.materielName + '_' + item.specification,
          ...item,
        }
      })
    }

    const handleChangeMateria = value => {
      if (!value) return
      if (!Reflect.has(props.specRowMsg, 'id')) {
        const selectObj = materialOptions.value.find(item => item.value == value)
        props.specRowMsg.emptyBottleName = selectObj.materielName
        props.specRowMsg.emptyBottleSpec = selectObj.specification
        props.specRowMsg.emptyBottleUnit = selectObj.assistMeasurementUnitName
      }
    }

    const visible = computed(() => {
      return props.dialogVisible
    })

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const changeImage = async file => {
      file.fileName = file.file.name
      const res = await uploadFileMixin(
        file,
        uploadRule.businessType,
        () => {
          form.loadText = '正在火速上传图片...'
          form.loading = true
          form.imgs.push(file)
          props.specRowMsg.emptyBottleUrl = ''
        },
        fileUrl => {
          form.loading = false
          props.specRowMsg.emptyBottleUrl = fileUrl
        },
      )
    }

    const add = params => {
      API.saveTbGoodsEmptyBottle(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('保存成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
            form.loading = false
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }
    const update = params => {
      API.updateTbGoodsEmptyBottle(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('更新成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
            form.loading = false
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }

    const rules = reactive({
      emptyBottleName: [{ required: true, message: '请填写空瓶商品名称', trigger: 'change' }],
      materielCode: [{ required: true, message: '请选择关联物料', trigger: 'change' }],
      emptyBottleUnit: [{ required: true, message: '请选择最小单位', trigger: 'change' }],
    })

    const elForm = ref(null)

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          form.loading = true
          form.loadText = '正在火速处理数据...'
          let params = {
            ...props.specRowMsg,
          }
          if (Reflect.has(props.specRowMsg, 'id')) {
            update(params)
          } else {
            add(params)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return {
      handleSubmit,
      form,
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      changeImage,
      rules,
      elForm,
      materialOptions,
      handleChangeMateria,
      uploadRule,
    }
  },
})
</script>
<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
}
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 150px !important;
  }
}
</style>
